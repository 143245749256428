
/* General */

.c-app {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
}
.flex-center {
	align-items: center!important;
}
.flex-end {
    align-items: flex-end !important;
}
.flex-justify-end {
    justify-content: end!important;
}
.cursor-pointer {
    cursor: pointer;
}
.animate-rotate {
    transition: .3s all;
}
.animate-rotate:hover {
    transform: rotate(15deg);
}
.text-dark-blue {
    color: var(--color) !important;
} 
.input-xs {
    max-width: 100%;
    width: 80px;
}
.input-sm {
    max-width: 100%;
    width: 120px;
}
.input-md {
    max-width: 100%;
    width: 220px;
}
.spinner-border {
	margin: 30px;
}
.spinner-border-sm {
	margin: 10px;
}
.dropdown-toggle::after {
    margin-bottom: -2px;
}
.react-datepicker-wrapper {
    width: auto!important;
}
.collapse-horizontal.collapsing {
    height: auto!important;
    transition: width 0.35s ease;
    width: 0;
}
.collapse-horizontal.collapse.show {
    width: 100%;
}
div#collapseWidth {
    overflow: hidden;
    display: inline-block;
    text-align: left;
}
.focus-shadow-0:focus {
    box-shadow: none!important;
}
.overview-box .card-body {
    max-height: 25%;
}


/* Colors */

.btn:not(.btn-link),.btn:not(.btn-link):hover,.btn:not(.btn-link):focus,.btn:not(.btn-link):disabled{
    color: white;
}

.bg-gradient-body {
    background: #3a750d !important;
    background: linear-gradient(0deg, #3a750d 0%, #3a750dcc 100%) !important;
    border-color: #3a750d !important;
}
.bg-gradient-blue {
    background: #2982cc!important;
    background: linear-gradient(45deg,#39f,#2982cc)!important;
    border-color: #2982cc!important;
}
.bg-gradient-purple {
    background: #9c1cd0 !important;
    background: linear-gradient(0deg, #c250f0 0%, #9c1cd0 100%) !important;
    border-color: #9c1cd0 !important;
}
.bg-gradient-yellow {
    background: #f6960b !important;
    background: linear-gradient(45deg, #f9b115, #f6960b) !important;
    border-color: #f6960b !important;
}
.bg-gradient-red {
    background: #e55353;
    background: linear-gradient(45deg, #f25454, #e55353) !important;
    border-color: #e55353 !important;
}
.bg-gradient-green {
    background: #2eb85c !important;
    background: linear-gradient(45deg, #41c86e, #2eb85c) !important;
    border-color: #2eb85c !important;
}
.bg-gradient-pink {
    background: #da54d1;
    background: linear-gradient(45deg, #f071e8, #da54d1) !important;
    border-color: #da54d1 !important;
}

.color-white-transparent {
    color: #ffffffbd;
}


/* Sidebars */

.c-sidebar-brand{
	background-color: white!important;
	background-image: url(../assets/logo.png)!important;
    background-size: auto 90% !important;
    background-repeat: no-repeat !important;
    background-position: 16px 0!important;
}
.c-header-brand{
	width: 100px;
	height: 50px;
	display: block;
	background-color: white!important;
	background-image: url(../assets/logo.png)!important;
	background-size: contain!important;
    background-repeat: no-repeat!important;
    background-position: center!important;
}



/* Buttons */

.btn-generator:not(.selected) {
    opacity: .3;
}
.btn-generator:not(.selected):hover {
    opacity: 0.6;
}
.btn-generator.selected {
	transform: scale(1.07);
}



/* Links */

.page-item.active .page-link {
    background-color: #303C54;
    border-color: #303C54;
}
.page-link {
	color: #303C54;
}



/* Multiselect */

.multiselect-container {
    min-width: 150px;
}
.multiselect-container .chip {
    margin-bottom: 1px;
    margin-top: 1px;
    background-color: #303c50;
}
.multiselect-container, .multiselect-container > div {
    width:100%
}
.multiselect-container #search_input {
    width: 50px;
}
.multiSelectContainer  {
    max-height: 35px;
}
.searchWrapper {
    display: flex;
    overflow-x: scroll;
    padding: 2px 5px 1px!important;
}
/* height */
.searchWrapper::-webkit-scrollbar {
height: 4px;
}

/* Track */
.searchWrapper::-webkit-scrollbar-track {
background: white; 
}

/* Handle */
.searchWrapper::-webkit-scrollbar-thumb {
background: #888; 
}

/* Handle on hover */
.searchWrapper::-webkit-scrollbar-thumb:hover {
background: #555; 
}


/* Tables */

.table-header-center th:not(:first-child) {
    text-align: center;
}
.table-item-circle { 
    height: 34px;
    width: 34px;
    padding: 6px 2px 6px 2px;
    text-align: center;
    border-radius: 50%;
    margin: -7px 0;
}
.monitor-park-table span.badge {
    width: 18px;
    height: 18px;
    display: block;
    border-radius: 50%;
}
.monitor-park-table tbody tr:first-child {
    font-weight: 700;
}
.monitor-park-table tbody tr:first-child td {
    background: #e1e2ea;
}
.col-solar .monitor-park-table tbody tr:first-child td {
    background: #f6f6b2;
}
.col-solar .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(214, 215, 0, 0.1);
}
.table td {
    vertical-align: middle;
}



/* Datepicker */

option[value="xx"] {
	display: none;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, 
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, 
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after, 
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    left: -22px!important;
}
@media only screen and (min-width:1200px) {
    #period {
        min-width: 186px;
    }
}


/* Others */

.search-wrapper.searchWrapper {
    background-color: white;
    padding: 3px 5px;
}


/* Menu */

.c-sidebar-nav-dropdown .c-sidebar-nav-dropdown-items > .c-sidebar-nav-item > a, .c-sidebar-nav-dropdown .c-sidebar-nav-dropdown-items > .c-sidebar-nav-dropdown > a {
    padding-left: 68px!important;
}
.c-sidebar-nav-dropdown .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown-items > .c-sidebar-nav-item > a, .c-sidebar-nav-dropdown .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown-items > .c-sidebar-nav-dropdown > a {
    padding-left: 82px!important;
}
li.c-sidebar-nav-item a[addlinkclasses="premium"] {
    background: #027509!important;
    border-top: 2px solid #407240;
    padding: 0.7rem 1rem;
    border-bottom: 2px solid #305b30;
}
a.c-sidebar-nav-link[addlinkclasses="premium"]:after {
    content: "";
    background-image: url(../assets/icons/premium.png);
    width: 30px;
    height: 30px;
    background-position: 0;
    background-size: 19px;
    background-repeat: no-repeat;
    right: 4px;
    position: absolute;
}


/* Maintenance */

select.open, .select-status option[value="open"] {
    background-color: #ffd8d8!important;
}
select.closed, .select-status option[value="closed"] {
    background-color: #ddffd8!important;
}


/* Language selector */

#flags-select ul {
    background: transparent;
    border: none;
    padding: 0;
}
#flags-select  {
    position: absolute;
    right: 5px;
    top: 5px;
}
#app-lang-btn {
    border: none;
    outline: none;
}
#flags-select li:hover {
    background: transparent;
}
#flags-select li svg {
    transition: .2s all ease;
}
#flags-select li:hover svg {
    transform: scale(1.2);
}


/* Google Maps */

.modal-google-map .modal-dialog {
    max-width: 670px!important;
}
.map-h2 {
    text-transform: uppercase;
    font-size: 1rem;
    padding: 20px;
    padding-left: 10px;
    text-align: center;
}
.google-map {
    width: 100%;
    height: 60vh;
}
.pin {
    display: flex;
    align-items: center;
    width: 180px;
    color: var(--main-blue);
}
.pin-icon {
    font-size: 4rem;
    height: 46px;
    margin-left: -15px;
    margin-top: -55px;
}
.pin-text {
    font-size: 1.3em;
}

@media screen and (min-width: 799px) {
    .google-map {
        height: 80vh;
    }

    .map-h2 {
        font-size: 1.3rem;
        font-weight: 400;
    }

    .pin {
        width: 15vw;
    }

    .pin-icon {
        font-size: 10vw;
    }
}


/* Overview */
.large-number {
    font-size: 9em;
    line-height: .9em;
    font-weight: 700;
    text-align: center;
    letter-spacing: -4px;
}
.large-number-units {
    font-size: 2rem;
    margin-left: 10px;
    line-height: 2rem;
    font-weight: 700;
}